import React, { useState, useRef, useEffect } from 'react'
import Layout from '../components/layout';
import Seo from '../components/seo';
import { connect } from 'react-redux';
import * as testimonialStyles from './testimonials.module.scss';
import { Link } from 'gatsby';
import Splider from '../components/ux/carousel/splider';
import { Image } from '../components/ux/image';

import testimoial1Image from '../images/testimonials/testimonial-1.png';
import testimoial1ImageLarge from '../images/testimonials/testimonial-1@2x.png';
import testimoial2Image from '../images/testimonials/testimonial-2.png';
import testimoial2ImageLarge from '../images/testimonials/testimonial-2@2x.png';
import testimoial3Image from '../images/testimonials/testimonial-3.png';
import testimoial3ImageLarge from '../images/testimonials/testimonial-3@2x.png';
import testimoial4Image from '../images/testimonials/testimonial-4.png';
import testimoial4ImageLarge from '../images/testimonials/testimonial-4@2x.png';
import testimoial5Image from '../images/testimonials/testimonial-5.png';
import testimoial5ImageLarge from '../images/testimonials/testimonial-5@2x.png';
import Youtube from '../components/ux/youtube/youtube';
import { useCookies } from 'react-cookie';
import { TweenLite } from 'gsap/all';


function YoutTubeConsentPopup({ setCookieConsent, setYoutubeAllowed }) {

    const dispose = () => {
        TweenLite.to('#youtube-consent-popup', 0.5, { autoAlpha: 0 });
    }

    const activateYT = (e) => {
        e.preventDefault();
        console.log('activate yt');
        setCookieConsent('bodyshake_youtube_consent', 'true', { path: '/' });
        setYoutubeAllowed(true);
        dispose();
    }

    return (
        <div id="youtube-consent-popup" className={`${testimonialStyles.blurMask}`} onClick={dispose} role="button" tabIndex={0} onKeyDown={e => dispose()} >
            <div className={`${testimonialStyles.youtubeConsentPopup}`}>
                <h2>Wir benötigen Ihre Zustimmung, um den YouTube Video-Service zu laden!</h2>
                <p className="mb-2">Wir verwenden einen Service eines Drittanbieters, um Videoinhalte einzubetten. Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch und stimmen Sie der Nutzung des Service zu, um dieses Video anzusehen.</p>
                <button onClick={(e) => activateYT(e)} className='btn btn-primary'>Akzeptieren</button>
            </div>
        </div>
    );
}


function Testimonials({ translations }) {

    const COOKIE_NAME = 'bodyshake_youtube_consent';
    const [youtubeAllowed, setYoutubeAllowed] = useState(false);
    const [cookieConsent, setCookieConsent] = useCookies(COOKIE_NAME);
    const [dimensions, setDimensions] = useState({ width: 444, height: 249 });

    useEffect(() => {
        // Check if in browser
        if (typeof window !== 'undefined' && window.innerWidth < 444) {
            let w = window.innerWidth - 60;
            console.log('calc width', w);
            setDimensions({ width: w, height: w * 0.5625 });
        }
    }, []);


    useEffect(() => {
        console.log('cookie consent', cookieConsent);
        if (cookieConsent[COOKIE_NAME] === 'true') {
            setYoutubeAllowed(true);
        }
    }, [cookieConsent]);

    const targetRef = useRef(null);

    const handleScroll = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleImageClick = (e) => {
        e.preventDefault();
        TweenLite.to('#youtube-consent-popup', 0.5, { autoAlpha: 1 });
    }




    return (
        <div>
            <Layout >
                <Seo title={translations.testimonials_title} description={translations.testimonials_meta_description} />
                <YoutTubeConsentPopup setCookieConsent={setCookieConsent} setYoutubeAllowed={setYoutubeAllowed} />
                <section className={`${testimonialStyles.hero}`}>
                    <div className={`${testimonialStyles.contentHolder}`}>
                        <div className={`${testimonialStyles.headline} yellow ${testimonialStyles.shadow}`}>PERSONALLOSER PROTEIN SHAKE VERKAUF</div>
                        <div className={`${testimonialStyles.big} ${testimonialStyles.shadow}`}>DER AUTOMAT ZUR MAXIMIERUNG DEINER ERGEBNISSE</div>
                        <div className={`${testimonialStyles.introtext} ${testimonialStyles.shadow}`}>Nutze intelligente Automatentechnologie aus Deutschland, um deine Shakeumsätze personallos signifikant zu steigern, deine Kunden zu begeistern und damit deinen Gewinn zu maximieren.</div>
                        <div className='flex sb'>
                            <Link to="/contact-us" className={`${testimonialStyles.btn}`}>Termin vereinbaren</Link>
                            <div
                                role='button'
                                tabIndex={0}
                                onClick={handleScroll}
                                className={`${testimonialStyles.btn} ${testimonialStyles.secondary} ml`}
                                onKeyDown={handleScroll}
                            >Kundenstimmen</div>
                        </div>
                    </div>
                    <div className={`${testimonialStyles.banner}`}>
                        <Splider />
                    </div>
                </section>
                <section className={`${testimonialStyles.ctaSection}`}>
                    <div className={`${testimonialStyles.formHeadline}`}>JETZT KONTAKT AUFNEHMEN UND BODYSHAKE KENNENLERNEN</div>
                    <Link to="/contact-us" className={`${testimonialStyles.btn} ${testimonialStyles.cta}`}>Persönlichen Beratungstermin vereinbaren</Link>
                </section>
                <section id="testimonials" ref={targetRef} className={`${testimonialStyles.testimonials}`}>
                    <div className={`${testimonialStyles.spotlight1}`}></div>
                    <div className={`${testimonialStyles.spotlight2}`}></div>
                    <div className={`${testimonialStyles.spotlight3}`}></div>


                    <div className={`${testimonialStyles.sectionHeadline}`} data-word="Kunden" style={{ "--headline-color": 'white', "--headline-padding-top": "5rem" }}>
                        <div>Kundenstimmen</div>
                        <div className={`${testimonialStyles.subline}`}>Das sagen erfolgreiche Unternehmer über die Zusammenarbeit mit uns</div>
                    </div>

                    <div className={`${testimonialStyles.testimonial} flex mt-0 center ${testimonialStyles.right}`}>
                        {
                            youtubeAllowed ?
                                (
                                    <Youtube videoId="CqDundsaMq4"
                                        additionalClasses={`${testimonialStyles.youtube}`}
                                        width={dimensions.width}
                                        height={dimensions.height}
                                        controls={true}
                                    />) : (
                                    <Image onClick={handleImageClick} id="img-michael" styles="no-scale" img={testimoial1Image} img2={testimoial1ImageLarge} alt="Torsten Stattmüller CEO Prime Fitness" />
                                )
                        }

                        <div className={`${testimonialStyles.text}`} >
                            <div className={`${testimonialStyles.quote}`}>»Mit dem Bodyshake-Konzept konnten wir unsere Betriebsergebnisse richtig nach
                                oben pushen.«</div>
                            <div className={`${testimonialStyles.name}`}>Torsten Stattmüller</div>
                            <div className={`${testimonialStyles.position}`}>Geschäftsführer von Prime Fitness und der Prime Physiotherapie in Lorsch</div>
                        </div>
                    </div>

                    <div className={`${testimonialStyles.testimonial} flex center ${testimonialStyles.left}`}>
                        <div className={`${testimonialStyles.text}`}>
                            <div className={`${testimonialStyles.quote}`}>»Mit BODYSHAKE® kann ich meinen Mitgliedern in unseren 5 Anlagen immer gleichbleibend hervorragende Qualität bieten.«</div>
                            <div className={`${testimonialStyles.name}`}>Moritz Raczkowsky</div>
                            <div className={`${testimonialStyles.position}`}>Betreibt erfolgreich 5 clever fit-Studios</div>
                        </div>
                        {
                            youtubeAllowed ?
                                (
                                    <Youtube videoId="TVP2v29MfkE"
                                        additionalClasses={`${testimonialStyles.youtube}`}
                                        width={dimensions.width}
                                        height={dimensions.height}
                                        controls={true}
                                    />) : (
                                    <Image styles="no-scale" onClick={handleImageClick} img={testimoial2Image} img2={testimoial2ImageLarge} alt="Moritz Raczkowsky Betreibt erfolgreich 5 clever fit-Studios" />
                                )
                        }
                    </div>

                    <div className={`${testimonialStyles.testimonial} flex mt-0 center ${testimonialStyles.right}`}>
                        {
                            youtubeAllowed ?
                                (
                                    <Youtube videoId="X61FWXjmXVM"
                                        additionalClasses={`${testimonialStyles.youtube}`}
                                        width={dimensions.width}
                                        height={dimensions.height}
                                        controls={true}
                                    />) : (
                                    <Image styles="no-scale" onClick={handleImageClick} img={testimoial3Image} img2={testimoial3ImageLarge} alt="Michael Maute - Geschäftsführer von Bestform in Albstadt" />
                                )
                        }

                        <div className={`${testimonialStyles.text}`}>
                            <div className={`${testimonialStyles.quote}`}>»Die Vorteile aus der Zusammenarbeit mit BODYSHAKE® sind zum einen eine deutlich höhere Kundenzufriedenheit und zum anderen der nun deutlich effektivere Mitarbeitereinsatz.«/div>
                                <div className={`${testimonialStyles.name}`}>Michael Maute</div>
                                <div className={`${testimonialStyles.position}`}>Geschäftsführer von Bestform in Albstadt</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${testimonialStyles.testimonial} flex center ${testimonialStyles.left}`}>
                        <div className={`${testimonialStyles.text}`}>
                            <div className={`${testimonialStyles.quote}`}>»Wir konnten in den ersten 10 Monaten der Zusammenarbeit mit BODYSHAKE® unseren Verkauf auf ca. 1.000 Shakes im Monat steigern.«</div>
                            <div className={`${testimonialStyles.name}`}>Daniela Fritz-Samsen</div>
                            <div className={`${testimonialStyles.position}`}>Geschäftsführerin von Injoy Berlin</div>
                        </div>
                        {
                            youtubeAllowed ?
                                (
                                    <Youtube videoId="pwax7cBLyEM"
                                        additionalClasses={`${testimonialStyles.youtube}`}
                                        width={dimensions.width}
                                        height={dimensions.height}
                                        controls={true}
                                    />) : (
                                    <Image styles="no-scale" onClick={handleImageClick} img={testimoial4Image} img2={testimoial4ImageLarge} alt="Daniela Fritz-Samsen - Geschäftsführerin von Injoy Berlin" />
                                )
                        }

                    </div>

                    <div className={`${testimonialStyles.testimonial} flex mt-0 center ${testimonialStyles.right}`}>
                        {
                            youtubeAllowed ?
                                (
                                    <Youtube videoId="jWXRpAkvUIA"
                                        additionalClasses={`${testimonialStyles.youtube}`}
                                        width={dimensions.width}
                                        height={dimensions.height}
                                        controls={true}
                                    />) : (
                                    <Image styles="no-scale" onClick={handleImageClick} img={testimoial5Image} img2={testimoial5ImageLarge} alt="René Meyer - Geschäftsführer von Injoy Reichenbach" />
                                )
                        }
                        <div className={`${testimonialStyles.text}`}>
                            <div className={`${testimonialStyles.quote}`}>»Der größte Mehrwert für uns in der Zusammenarbeit mit BODYSHAKE® ist die zuverlässige Arbeitsweise des Automaten, wodurch nun mehr Kapazitäten für die Kundenbetreuung zur Verfügung stehen.«</div>
                            <div className={`${testimonialStyles.name}`}>René Meyer</div>
                            <div className={`${testimonialStyles.position}`}>Geschäftsführer von Injoy Reichenbach</div>
                        </div>
                    </div>


                </section>
            </Layout>
        </div >
    );
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
})

export default connect(mapStateToProps)(Testimonials);