import React, { useEffect, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { Image } from './../image';

import Partner1 from './../../../images/testimonials/logos/bestform.png';
import Partner1Large from './../../../images/testimonials/logos/bestform@2x.png';
import Partner2 from './../../../images/testimonials/logos/body-culture.png';
import Partner2Large from './../../../images/testimonials/logos/body-culture@2x.png';
import Partner3 from './../../../images/testimonials/logos/clever-fit.png';
import Partner3Large from './../../../images/testimonials/logos/clever-fit@2x.png';
import Partner4 from './../../../images/testimonials/logos/easy-fitness.png';
import Partner4Large from './../../../images/testimonials/logos/easy-fitness@2x.png';
import Partner5 from './../../../images/testimonials/logos/ff.png';
import Partner5Large from './../../../images/testimonials/logos/ff@2x.png';
import Partner6 from './../../../images/testimonials/logos/fitplus.png';
import Partner6Large from './../../../images/testimonials/logos/fitplus@2x.png';
import Partner7 from './../../../images/testimonials/logos/fp.png';
import Partner7Large from './../../../images/testimonials/logos/fp@2x.png';
import Partner8 from './../../../images/testimonials/logos/goldjunge.png';
import Partner8Large from './../../../images/testimonials/logos/goldjunge@2x.png';
import Partner9 from './../../../images/testimonials/logos/i2pro.png';
import Partner9Large from './../../../images/testimonials/logos/i2pro@2x.png';
import Partner10 from './../../../images/testimonials/logos/injoy-fitness.png';
import Partner10Large from './../../../images/testimonials/logos/injoy-fitness@2x.png';
import Partner11 from './../../../images/testimonials/logos/kraftwerk24.png';
import Partner11Large from './../../../images/testimonials/logos/kraftwerk24@2x.png';
import Partner12 from './../../../images/testimonials/logos/mikro-eins.png';
import Partner12Large from './../../../images/testimonials/logos/mikro-eins@2x.png';
import Partner13 from './../../../images/testimonials/logos/pptpfc.png';
import Partner13Large from './../../../images/testimonials/logos/pptpfc@2x.png';
import Partner14 from './../../../images/testimonials/logos/rewolte.png';
import Partner14Large from './../../../images/testimonials/logos/rewolte@2x.png';
import Partner15 from './../../../images/testimonials/logos/sportpark.png';
import Partner15Large from './../../../images/testimonials/logos/sportpark@2x.png';

export default function Splider() {
    const splideRef = useRef(null);

    const scrollSpeed = 60; // Geschwindigkeit des Scrollens (Pixel pro Frame)

    useEffect(() => {
        // Sicherstellen, dass die Splide-Instanz existiert
        const splideElement = splideRef.current;
        if (!splideElement || !splideElement.splide) return;

        const track = splideElement.splide.root.querySelector('.splide__track');
        if (!track) return;

        let lastTime = 0;

        console.log(track.scrollWidth);

        // Funktion für das kontinuierliche Scrollen
        const animateScroll = (time) => {
            const delta = time - lastTime;
            lastTime = time;

            const trackWidth = track.scrollWidth;
            const currentPos = track.scrollLeft;
            const trackVisibleWidth = track.offsetWidth;  // Die sichtbare Breite des Tracks (Viewport)


            // Berechne die neue Position
            const newPos = currentPos + (scrollSpeed * delta / 1000); // Geschwindigkeit in Pixel pro Sekunde

            // console.log(newPos + ' >=  ' + trackWidth);

            // Wenn das Ende erreicht ist, gehe zurück zum Anfang (Endlosschleife)
            if (newPos >= trackWidth - trackVisibleWidth) {
                track.scrollLeft = 0;
            } else {
                track.scrollLeft = newPos;
            }

            // Fordere das nächste Frame an
            requestAnimationFrame(animateScroll);
        };

        // Start der Animation
        const requestId = requestAnimationFrame(animateScroll);

        // Cleanup: Stelle sicher, dass die Animation gestoppt wird, wenn die Komponente unmontiert wird
        return () => cancelAnimationFrame(requestId);
    }, []); // Leeres Dependency-Array, damit der Effekt nur einmal ausgeführt wird

    return (
        <Splide
            ref={splideRef}
            options={{
                type: 'loop',
                autoplay: false,
                drag: false,
                pagination: false,
                arrows: false,
                gap: '.5rem',
                perPage: 7,
                breakpoints: {
                    700: {
                        perPage: 5,
                        gap: '1.5rem',
                    },
                },

            }}>
            <SplideSlide>
                <Image img={Partner1} img2={Partner1Large} alt="Testimonial 1" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner2} img2={Partner2Large} alt="Testimonial 2" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner3} img2={Partner3Large} alt="Testimonial 3" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner4} img2={Partner4Large} alt="Testimonial 4" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner5} img2={Partner5Large} alt="Testimonial 5" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner6} img2={Partner6Large} alt="Testimonial 6" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner7} img2={Partner7Large} alt="Testimonial 7" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner8} img2={Partner8Large} alt="Testimonial 8" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner9} img2={Partner9Large} alt="Testimonial 9" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner10} img2={Partner10Large} alt="Testimonial 10" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner11} img2={Partner11Large} alt="Testimonial 11" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner12} img2={Partner12Large} alt="Testimonial 12" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner13} img2={Partner13Large} alt="Testimonial 13" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner14} img2={Partner14Large} alt="Testimonial 14" />
            </SplideSlide>
            <SplideSlide>
                <Image img={Partner15} img2={Partner15Large} alt="Testimonial 15" />
            </SplideSlide>
        </Splide>
    )
}