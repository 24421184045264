import React, { useCallback, useEffect } from "react";
import styles from "./youtube.module.scss";

// Singleton zum Verwalten des API-Ladestatus
const YouTubeAPI = {
  isLoaded: false,
  isLoading: false,
  callbacks: [],

  loadAPI(callback) {
    if (this.isLoaded) {
      // API ist bereits geladen
      callback();
      return;
    }

    if (this.isLoading) {
      // API wird geladen, füge Callback zur Warteschlange hinzu
      this.callbacks.push(callback);
      return;
    }

    // Lade die API
    this.isLoading = true;
    this.callbacks.push(callback);

    const scriptTag = document.createElement("script");
    scriptTag.src = "https://www.youtube.com/iframe_api";
    scriptTag.onload = () => {
      window.onYouTubeIframeAPIReady = () => {
        this.isLoaded = true;
        this.isLoading = false;
        this.callbacks.forEach((cb) => cb());
        this.callbacks = [];
      };
    };
    document.body.appendChild(scriptTag);
  },
};

function Youtube({
  videoId,
  additionalClasses = null,
  width = 640,
  height = 390,
  controls = false,
  loop = false,
  autoplay = false,
}) {
  const containerId = `bsytplayer-${videoId}`; // Einzigartige ID für den Player

  const buildPlayer = useCallback(() => {
    if (!document.getElementById(containerId)) return;

    new window.YT.Player(containerId, {
      height: height,
      width: width,
      videoId: videoId,
      playerVars: {
        autoplay: autoplay ? 1 : 0,
        controls: controls ? 1 : 0,
        disablekb: 1,
        loop: loop ? 1 : 0,
        modestbranding: 1,
        rel: 0,
      },
      events: {
        onReady: () => console.log(`Player ${videoId} ready.`),
      },
    });
  }, [autoplay, controls, height, loop, videoId, width, containerId]);

  useEffect(() => {
    YouTubeAPI.loadAPI(() => {
      buildPlayer();
    });
  }, [buildPlayer]); // Läuft nur einmal

  return (
    <div
      id={containerId}
      className={`${styles.youtubeContainer} ${additionalClasses ?? ""}`}
    ></div>
  );
}

export default Youtube;
